<template>
    <div>
        <div class="contentWrapper"> 
            <v-list>
                <v-list-item>
                    <v-list-item-content style="background: #eeeeee">
                        <v-list-item-title class="ml-3 font-weight-medium">PROJECTS ASSIGNED</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
				<p style="text-align: center;" class="font-italic grey--text pt-5" v-if="workerProjects.length==0">No data</p>

				<div v-for="item in workerProjects" :key="item.projectId" class="mx-4 mt-3">
					<v-list-group
						:value="item.open"
					>
					<template v-slot:activator>
						<v-list-item-title class="primary--text">{{item.projectName.toUpperCase()}}</v-list-item-title>
						<v-list-item-action v-if="permissions.changeProjectAssignment" class="mr-8">
							<v-list-item-icon v-if="item.active"
								@click="openDeactivateDialog(item, 'Project')" 
								class="red--text my-0" 
								style="cursor: pointer">
								<v-icon class="red--text mr-1" dense> mdi-history</v-icon>
									Deactivate
							</v-list-item-icon>
							<v-list-item-icon v-else
								@click="changeProjectStatus(item)" 
								class="green--text my-0" 
								style="cursor: pointer">
								<v-icon class="green--text mr-2" dense> mdi-history</v-icon>
									Activate
							</v-list-item-icon>
						</v-list-item-action>
						<v-list-item-action v-if="permissions.deleteProjectAssignment">
							<RemoveRecord @confirmDelete="confirmProjectDelete($event)" :item="item" :title="item.projectName"  :width="'300'"></RemoveRecord>
						</v-list-item-action>	
					</template>
					<v-divider class="primary"></v-divider>

					<v-list v-if="permissions.viewShiftAssignment">
						<template v-for="shift in workerShifts[item.projectId]">
							<v-list-item  :key="shift.shiftId">
								<v-list-item-content>
									<div style="display:flex;">
									<span :style="{'color': item.active? 'black': 'gray'}">{{shift.shiftName}}</span>									
									<ShiftDetails :item="shift" class="ml-2"></ShiftDetails>
									</div>
								</v-list-item-content>
									
								<v-list-item-action v-if="permissions.changeShiftAssignment && item.active">
									<v-list-item-icon v-if="shift.shiftActive"
										@click="openDeactivateDialog(shift, 'Shift')" 
										class="red--text my-0" 
										style="cursor: pointer">
										<v-icon class="red--text mr-2" dense> mdi-history</v-icon>
											Deactivate
									</v-list-item-icon>
									<v-list-item-icon v-else
										@click="changeShiftStatus(shift)" 
										class="green--text my-0" 
										style="cursor: pointer">
										<v-icon class="green--text mr-2" dense> mdi-history</v-icon>
											Activate
									</v-list-item-icon>
								</v-list-item-action>
								<v-list-item-action v-if="permissions.deleteShiftAssignment">
									<RemoveRecord @confirmDelete="confirmShiftDelete($event)" :item="shift" :title="shift.shiftName"  :width="'300'"></RemoveRecord>
								</v-list-item-action>	
							</v-list-item>
							<v-divider :key="'divider-' + shift.shiftId"></v-divider>
						</template>
					</v-list>

					<div style="text-align:end" v-if="projectsShifts[item.projectId] && (!workerShifts[item.projectId] || workerShifts[item.projectId].length<projectsShifts[item.projectId].length)">
						<v-btn
							fab
							dark
							x-small
							style="margin-right: 20px; margin-top: 10px;"
							color="orange"
							@click="openShiftAssignmentDialog(item.projectId)"
							:disabled="!item.active||!permissions.addShiftAssignment"
							class="mb-3"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</div>
					</v-list-group>
				</div>


				<div v-if="permissions.addProjectAssignment">
					<v-list-item class="mt-10">
						<v-list-item-content style="background: #eeeeee">
							<v-list-item-title class="ml-3 font-weight-medium">ADD PROJECT</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item class="mt-5">
						<v-list-item-content class="mr-5">
							<v-select
							v-model="selectedProject"
							:items="dropdownProjects"
							item-text="name"
							item-value="id"
							label="Project">
							</v-select>
						</v-list-item-content>
					</v-list-item>
					<p class="error--text">{{errorMessage}}</p>

					<v-row class="px-7 pb-7" style="display:flex; justify-content: flex-end;">
						<v-btn
						color="primary"
						class="px-15"
						@click="validateNewProjectAssignment()">
							ADD NEW
						</v-btn>
					</v-row>
				</div>
            </v-list>
        </div>

		<!-- Add shift assignment dialog -->
		<v-dialog 
        v-model="addShiftDialog.visible"
		width="300"
        persistent>
            <v-card>
                <v-card-title>Select shift to assign</v-card-title>	
                <v-card-text class="black--text py-2 messageWrapper">
                    <v-select
                    v-model="addShiftDialog.selectedShift"
                    :items="addShiftDialog.shifts"
                    item-value="shiftId"
                    item-text="shiftName"
                    label="Shift">
                    </v-select>

                    <p class="error--text">{{errorMessage}}</p>
                    
                    <div class="actionButtonsWrapper">
                        <v-btn
                        color="grey"
                        class="mr-0 mt-0"
                        text
						@click="closeShiftAssignmentDialog()"
                        >
                        CANCEL
                        </v-btn>
                        <v-btn
                        color="primary"
                        class="mr-0 mt-0"
                        text
						width="150"
						:disabled="!addShiftDialog.selectedShift"
						@click="addShiftAssignment(addShiftDialog.selectedShift), closeShiftAssignmentDialog()"
                        >
                        SAVE
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

		<!-- Confirm deactivation assignment dialog -->
		<v-dialog 
        v-model="deactivateDialog.visible"
		width="300"
        persistent>
            <v-card>
                <v-card-title>{{deactivateDialog.type}} deactivation</v-card-title>	
				<v-divider></v-divider>
                <v-card-text class="black--text pb-2 messageWrapper">
					<p class="font-weight-regular black--text mt-6 messageWrapper">Are you sure you want to deactivate {{deactivateDialog.itemName}}?</p>
					<div class="actionButtonsWrapper">
						<v-btn
						color="grey"
						class="mr-0 mt-0"
						text
						@click="closeDeactivateDialog()"
						>
						CANCEL
						</v-btn>
						<v-btn
						color="error"
						class="mr-0 mt-0"
						text
						@click="confirmDeactivation()"
						>
						CONFIRM
						</v-btn>
					</div>
				</v-card-text>
            </v-card>
        </v-dialog>

		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
	</div>
</template>

<script>
//import RemoveRecord from './RemoveRecord.vue'
const RemoveRecord = () => import('./RemoveRecord.vue');
//import ShiftDetails from './ShiftDetails.vue'
const ShiftDetails = () => import('./ShiftDetails.vue');
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'WorkerProjects',

	components: {
		RemoveRecord,
		ShiftDetails
	},

	data: function(){
		return {
			errorMessage: "",
            selectedProject: null,
			allProjects: [
				// {
				// 	name: 
				// 	id: 
				// }
			],
			mutableWorkerFullName: this.workerFullName,
			mutableWorkerId: this.workerId,
			workerProjects:[],
			workerShifts: [],
			shifts: [],
			addShiftDialog: {
				visible: false,
				projectId: null,
				selectedShift: null,
				shifts: []
			},
			deactivateDialog: {
				type: null,
				visible: false,
				itemId: null,
				itemName: null,
				item: null
			},
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
			permissions: {
				addProjectAssignment: true,
				changeProjectAssignment: true,
				deleteProjectAssignment: true,
				viewShiftAssignment: true,
				addShiftAssignment: true,
				changeShiftAssignment: true,
				deleteShiftAssignment: true,
			}
		}
	},
    props: {
		workerFullName: String,
		workerId: Number
    },
	computed:{
		lviews: {
			get: function() {
				return this.$t('views.configurationPanel');
			}
		},
		projectsShifts(){
			let projectsShifts={}
			if(this.shifts){
				for(let item of this.shifts){
					if(!(item.projectId in projectsShifts)){
						projectsShifts[item.projectId]=[]
					}
					projectsShifts[item.projectId].push(item)
				}
			}
			return projectsShifts
		},
		dropdownProjects(){
			let projects =[]
			if(this.workerProjects && this.allProjects){
				for(let item of this.allProjects){
					if(!this.workerProjects.find(i => i.projectId==item.id)){
						projects.push({
							id: item.id,
							name: item.name
						})
					}
				}
			}
			return projects
		},
		dropdownShifts(){
			let ddShifts=[]
			if(this.projectsShifts && this.selectedProject && this.workerShifts){
				if(this.projectsShifts[this.selectedProject]){
					for(let item of this.projectsShifts[this.selectedProject]){
						if(!(this.selectedProject in this.workerShifts)||!(this.workerShifts[this.selectedProject].find(i=>item.shiftId==i.shiftId))){
							ddShifts.push({
								name: item.shiftName,
								id: item.shiftId
							})
						}
					}
				}
			}
			return ddShifts
		}
	},
	watch:{
	},
	beforeMount(){
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
		}
	},
    mounted() {
		//setting permissions
		let userPermissions = JSON.parse(localStorage.permissions)
		if (!userPermissions.includes("view_workerprojectassignment")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return
		}
		if (!userPermissions.includes("add_workerprojectassignment")) {
            this.permissions.addProjectAssignment = false
		}
		if (!userPermissions.includes("change_workerprojectassignment")) {
            this.permissions.changeProjectAssignment = false
		}
		if (!userPermissions.includes("delete_workerprojectassignment")) {
            this.permissions.deleteProjectAssignment = false
		}
		if (!userPermissions.includes("view_workershiftassignment")) {
            this.permissions.viewShiftAssignment = false
		}
		if (!userPermissions.includes("add_workershiftassignment")) {
            this.permissions.addShiftAssignment = false
		}
		if (!userPermissions.includes("change_workershiftassignment")) {
            this.permissions.changeShiftAssignment = false
		}
		if (!userPermissions.includes("delete_workershiftassignment")) {
            this.permissions.deleteShiftAssignment = false
		}
		
		if(this.mutableWorkerFullName && this.mutableWorkerId){
			localStorage.cardsWorkerFullName=JSON.stringify(this.mutableWorkerFullName)
			localStorage.cardsWorkerId=JSON.stringify(this.mutableWorkerId)
		}
		else{
			this.mutableWorkerFullName = JSON.parse(localStorage.cardsWorkerFullName)
			this.mutableWorkerId = JSON.parse(localStorage.cardsWorkerId)
		}
		this.getProjectsAssignmentsFromApi()
		this.getProjectsFromApi()
		this.getShiftsFromApi()
		this.getShiftsAssignmentsFromApi()
	},
    methods: {
		getProjectsFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				params: {
					type: 1
				}
			}).then((response) => {
				this.adaptApiResponseProjects(response.data)
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getShiftsFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/shifts/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			}).then((response) => {
				this.shifts=response.data
				this.adaptApiResponseShifts(response.data)
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				console.log(error)
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getProjectsAssignmentsFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
					method:'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+this.mutableWorkerId+"/projects-assign/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				}).then((response) => {
					this.adaptApiResponseProjectAssignments(response.data)
					this.$emit("set-state", "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		getShiftsAssignmentsFromApi(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Loading data...");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+this.mutableWorkerId+"/shifts/",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				}
			}).then((response) => {
				this.adaptApiResponseShiftsAssignments(response.data)
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				console.log(error)
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		saveProjectChanges(project, newStatus){
			this.$emit("set-state", "LOADING_OVERLAYER", "Saving project assignment changes...");
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects-assign/"+project.id,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						"status": newStatus? 0: 1,
					}
				}).then(() => {
					project.active=newStatus
					this.$emit("set-state", "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		addProjectAssignment(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Creating project assignment...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects-assign/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						"worker_id": this.mutableWorkerId,
						"project_id": this.selectedProject,
					}
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					if(this.selectedShift){
						this.addShiftAssignment()
					}
					else{
						this.reload()
					}
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error.response.data)
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		deleteProjectAssignment(assignId){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing project assignment...");
			axios({
					method:'delete',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/projects-assign/"+assignId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
				}).then(() => {
					this.getProjectsAssignmentsFromApi()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		saveShiftChanges(shift, newStatus){
			this.$emit("set-state", "LOADING_OVERLAYER", "Saving shift assignment changes...");
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/shifts/"+shift.id,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						"status": newStatus? 0: 1,
					}
				}).then(() => {
					shift.shiftActive=newStatus
					this.$emit("set-state", "DEFAULT");
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
					if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		addShiftAssignment(shiftId){
			this.$emit("set-state", "LOADING_OVERLAYER", "Creating shift assignment...");
			axios({
					method:'post',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/shifts/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						"worker_id": this.mutableWorkerId,
						"shift_id": shiftId,
					}
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					this.reload()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
						if (typeof(error.response) === 'undefined'){
						this.errorDialog.errorTitle = "Network error";
						this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
						this.errorDialog.model = true;
						return;
					}

					switch(error.response.status){
						case 401:
							// Redirect to login page
							this.$router.push("/");
							return;
						case 403:
							this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
							return;
						case 429:
							this.errorDialog.errorTitle = "Too many requests";
							this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
							this.errorDialog.model = true;
							return;
						case 400:
							this.errorDialog.errorTitle = "Request rejected";
							this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
							this.errorDialog.model = true;
							return;
						case 500:
							this.errorDialog.errorTitle = "Server error";
							this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
							this.errorDialog.model = true;
							return;
						default:
							this.errorDialog.errorTitle = "Unknown error";
							this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
							this.errorDialog.model = true;
							return;
					}
			})
		},
		deleteShiftAssignment(assignId){
			this.$emit("set-state", "LOADING_OVERLAYER", "Removing shift assignment...");
			axios({
					method:'delete',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/shifts/"+assignId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
				}).then(() => {
					this.getShiftsAssignmentsFromApi()
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					console.log(error)
					if (typeof(error.response) === 'undefined'){
						this.errorDialog.errorTitle = "Network error";
						this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
						this.errorDialog.model = true;
						return;
					}

					switch(error.response.status){
						case 401:
							// Redirect to login page
							this.$router.push("/");
							return;
						case 403:
							this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisAction);
							return;
						case 429:
							this.errorDialog.errorTitle = "Too many requests";
							this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
							this.errorDialog.model = true;
							return;
						case 400:
							this.errorDialog.errorTitle = "Request rejected";
							this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
							this.errorDialog.model = true;
							return;
						case 500:
							this.errorDialog.errorTitle = "Server error";
							this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
							this.errorDialog.model = true;
							return;
						default:
							this.errorDialog.errorTitle = "Unknown error";
							this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
							this.errorDialog.model = true;
							return;
					}
			})
		},
		adaptApiResponseProjectAssignments(items){
			let projects = []
			if(items){
				for(let item of items){
					projects.push({
                        id: item.id,
						projectId: item.project_id,
						projectName: item.project_name,
						active: item.status? false: true,
						open: false
					})
				}
			}
			if(projects.length==1){
				projects[0].open=true
			}
			this.workerProjects=projects
		},
		adaptApiResponseProjects(items){
			let projects =[]
			if(items){
				for(let item of items){
						projects.push({
							id: item.id,
							name: item.name
						})
				}
			}
			this.allProjects=projects
		},
		adaptApiResponseShifts(items){
			let shifts=[]
			if(items){
				for(let item of items){
					let newShift = {
						projectId: item.project.id,
						shiftId: item.id,
						shiftName: item.name,
						shiftDescription: item.description,
						shiftStartDate: item.shift_date? item.shift_date.start_date: null,
						shiftEndDate: item.shift_date? item.shift_date.end_date: null,
						startTime: item.shift_timing.default_start_time,
						endTime: this.countShiftEndTime(item.shift_timing.default_start_time, item.shift_timing.default_duration), //do wyliczenia
						settlement: item.shift_rounding.settlements? "Work schedule": "Work time",
						enteringRoundingType: null,
						enteringRounding: item.shift_rounding.start_rounding,
						leavingRoundingType: null,
						leavingRounding: item.shift_rounding.end_rounding
					}
					switch(item.shift_rounding.start_rounding_type){
						case 0:
							newShift.enteringRoundingType="Shift default"
							break;
						case 1:
							newShift.enteringRoundingType="Nearest"
							break;
						case 2:
							newShift.enteringRoundingType="Up"
							break;
						case 3:
							newShift.enteringRoundingType="Down"
							break;
						default:
							newShift.enteringRoundingType="Unknown"
					}
					switch(item.shift_rounding.end_rounding_type){
						case 0:
							newShift.leavingRoundingType="Shift default"
							break;
						case 1:
							newShift.leavingRoundingType="Nearest"
							break;
						case 2:
							newShift.leavingRoundingType="Up"
							break;
						case 3:
							newShift.leavingRoundingType="Down"
							break;
						default:
							newShift.leavingRoundingType="Unknown"
					}
					

					shifts.push(newShift)
				}
			}
			this.shifts=shifts
		},
		adaptApiResponseShiftsAssignments(items){
			let workerShifts={}
			if(items){
				for(let item of items){
					if(!(item.shift.project.id in workerShifts)){
						workerShifts[item.shift.project.id]=[]
					}
					let newShift = {
						id: item.id,
						shiftId: item.shift.id,
						shiftName: item.shift.name,
						shiftActive: item.status? false: true,
						shiftDescription: item.shift.description,
						shiftStartDate: item.shift.shift_date? item.shift.shift_date.start_date: null,
						shiftEndDate: item.shift.shift_date? item.shift.shift_date.end_date: null,
						startTime: item.shift.shift_timing.default_start_time,
						endTime: this.countShiftEndTime(item.shift.shift_timing.default_start_time, item.shift.shift_timing.default_duration), //do wyliczenia
						settlement: item.shift.shift_rounding.settlements? "Work schedule": "Work time",
						enteringRoundingType: null,
						enteringRounding: item.shift.shift_rounding.start_rounding,
						leavingRoundingType: null,
						leavingRounding: item.shift.shift_rounding.end_rounding
					}
					switch(item.shift.shift_rounding.start_rounding_type){
						case 0:
							newShift.enteringRoundingType="Shift default"
							break;
						case 1:
							newShift.enteringRoundingType="Nearest"
							break;
						case 2:
							newShift.enteringRoundingType="Up"
							break;
						case 3:
							newShift.enteringRoundingType="Down"
							break;
						default:
							newShift.enteringRoundingType="Unknown"
					}
					switch(item.shift.shift_rounding.end_rounding_type){
						case 0:
							newShift.leavingRoundingType="Shift default"
							break;
						case 1:
							newShift.leavingRoundingType="Nearest"
							break;
						case 2:
							newShift.leavingRoundingType="Up"
							break;
						case 3:
							newShift.leavingRoundingType="Down"
							break;
						default:
							newShift.leavingRoundingType="Unknown"
					}
					workerShifts[item.shift.project.id].push(newShift)
				}
			}
			this.workerShifts=workerShifts
		},
		getShiftById(id){
			if(this.shifts){
				return this.shifts.find(item => item.shiftId==id)
			}
		},
		countShiftEndTime(startTime, duration){
			if(startTime){
				let date=new Date()
				date.setHours(startTime.substr(0,2), startTime.substr(3,2),startTime.substr(6,2),0)
				date.setMinutes(date.getMinutes()+duration)
				return this.timeToString(date.getTime())
			}
			return null
		},
		timeToString(time){
			let date=new Date(time)
			let hours = date.getHours()
			let minutes = date.getMinutes()
			let seconds = date.getSeconds()

			if(hours<10){
				hours="0"+hours
			}
			if(minutes<10){
				minutes="0"+minutes
			}	
			if(seconds<10){
				seconds="0"+seconds
			}	
			return hours+":"+minutes+":"+seconds
		},
		changeProjectStatus(item){
			this.saveProjectChanges(item, !item.active)
		},
		changeShiftStatus(item){
			this.saveShiftChanges(item, !item.shiftActive)
		},
		validateNewProjectAssignment(){
			if(this.selectedProject==null){
				this.errorMessage="Select project."
				return
			}
			this.addProjectAssignment()
		},
		confirmProjectDelete(item){
			item.deleted=true
			this.deleteProjectAssignment(item.id)
		},
		confirmShiftDelete(item){
			this.deleteShiftAssignment(item.id)
		},
		openDeactivateDialog(item, type){
			this.deactivateDialog.visible=true,
			this.deactivateDialog.item=item,
			this.deactivateDialog.type=type
			this.deactivateDialog.itemId=item.id
			if(type=="Shift"){
				this.deactivateDialog.itemName=item.shiftName
			}
			else{
				this.deactivateDialog.itemName=item.projectName
			}
		},
		closeDeactivateDialog(){
			this.deactivateDialog.visible=false,
			this.deactivateDialog.item=null,
			this.deactivateDialog.type=null
			this.deactivateDialog.itemId=null
			this.deactivateDialog.itemName=null
		},
		confirmDeactivation(){
			if(this.deactivateDialog.type=="Shift"){
				this.changeShiftStatus(this.deactivateDialog.item)
			}
			else{
				this.changeProjectStatus(this.deactivateDialog.item)
			}
			this.closeDeactivateDialog()
		},
		openShiftAssignmentDialog(projectId){
			this.addShiftDialog.visible=true
			for(let item of this.projectsShifts[projectId]){
				if(!this.workerShifts[item.projectId] || !(this.workerShifts[item.projectId].find(i => i.shiftId==item.shiftId))){
					this.addShiftDialog.shifts.push(item)
				}
			}
		},
		closeShiftAssignmentDialog(){
			this.addShiftDialog.visible=false,
			this.addShiftDialog.selectedShift=null
			this.addShiftDialog.shifts=[]
		},
		reload(){
			this.selectedProject=null
			this.selectedShift=null
			this.errorMessage=""
			this.getProjectsAssignmentsFromApi()
			this.getShiftsAssignmentsFromApi()
		}
    }
	};
</script>

<style scoped>

.contentWrapper{
    padding: 20px;
    width: 600px;
	overflow:visible;
}

.sectionTitleWrapper{
    background: #eeeeee;
    font-weight: bolder;
    padding: 5px;
    display: flex;
    align-content: center;
}

.table{
	border: 1px solid black;
}

.tableRow{
	display: grid;
	grid-template-columns: 2fr 2fr 3fr;
}

.tableHeader{
	background: #cccccc;
}


</style>
